













































































































































































































































































































































































































































































































import { useState, useGetters } from '@u3u/vue-hooks'
import {
  computed,
  defineComponent,
  onBeforeMount,
  ref,
  watch,
} from '@vue/composition-api'
import axios from 'axios'
import { getApiUrl } from '@/inc/app.config'
import { logger } from '@/inc/utils'
import auth from '@/composables/auth'
import Switcher from '@/components/ui/Switcher.vue'
import TerminalAddress from '@/components/my/terminals/TerminalAddress.vue'
import VInput from '@/components/form/VInput.vue'
import Upload from '@/components/Upload.vue'
import CustomLink from '@/components/g/CustomLink.vue'
import VDatepicker from '@/components/form/VDatepicker.vue'
import VSelect from '@/components/form/VSelect.vue'
import moment from 'moment'
import Rgpd from '@/components/Rgpd.vue'
import VueRecaptcha from 'vue-recaptcha'
import VSelectgood from '@/components/form/VSelectGood.vue'
import SelectPhoneFlag from '@/components/form/selectPhoneFlag.vue'
import { extend } from 'vee-validate'
import { regex, required } from 'vee-validate/dist/rules.umd.js'

extend('regex', regex)
extend('required', required)

export default defineComponent({
  name: 'ChargingTerminalsEdit',
  components: {
    SelectPhoneFlag,
    VSelectgood,
    Rgpd,
    VDatepicker,
    CustomLink,
    Switcher,
    TerminalAddress,
    VInput,
    VSelect,
    Upload,
    VueRecaptcha,
  },
  setup(_props, ctx) {
    const { $route } = ctx.root
    const { uuid } = $route.params

    const { user } = useState(['user'])

    const { i18n } = useState('my', ['i18n'])
    const { chrome } = useState(['chrome'])
    const globalI18n = computed(() => chrome.value.data)

    /* const terminal = ref([])*/
    const terminal = ref({
      ean: '',
      borne: {
        adresse: {
          rue: '',
          numero: '',
          codePostal: '',
          commune: '',
          pays: '',
        },
        marque: '',
        serial: '',
        ean: '',
        utilisation: {
          libelle: '',
          valeur: '',
        },
        puissance: 0,
        photo: '',
        modele: '',
        bidirectionnelle: false,
        dateDesactivation: '',
        dateActivation: '',
        active: true,
      },
      entreprise: {
        formeJuridique: '',
        numero: '',
        nom: '',
        acronyme: '',
      },
      dateCreation: '',
      uuid: '',
      demandeur: {
        prenom: '',
        email: '',
        telephone: '',
        nom: '',
      },
      installateur: {
        telephone: '',
        nom: '',
        email: '',
      },
    })

    const inputUpload = ref([])
    const changeFile = ref(true)

    const deleteFile = () => {
      changeFile.value = true
      terminal.value.borne.photo = ''
    }

    const onDelete = event => {
      inputUpload.value = []
    }
    const onInputFile = e => {
      const [event] = e
      if (event.file) {
        axios
          .get(
            `${getApiUrl()}/file/temp?Submit=false&FileName=${
              event.file.lastModified
            }-${event.file.name}`
          )
          .then(response => {
            if (response.status === 200 || response.status === 201) {
              const customAxios = axios.create()
              delete customAxios.defaults.headers.common.Authorization
              customAxios
                .put(response.data.putUrl, event.file, {
                  headers: {
                    'Content-Type': '*',
                  },
                })
                .then(responsePut => {
                  terminal.value.borne.photo = response.data.getUrl
                })
            }
          })
          .catch(e => {
            logger.trace(e)
            logger.trace('[terminal:loadFile] FAILED ')
          })
      }
    }

    const autreFr = ref('Autre')
    const autreDe = ref('Andere')

    const formName = 'myUserForm'
    const isLoad = ref(false)
    const isFindTerminal = ref(false)

    const etatBorne = ref(true)
    const dateMEF = ref(moment(new Date()))
    const dateMHF = ref(moment(new Date()))
    const puissanceSelect = ref()
    const installateurActif = ref(false)
    const utilisationPublic = ref(true)
    const isBidirectionnelle = ref(true)
    const formValid = ref(false)
    const politiqueValid = ref(false)

    const phoneCode = ref('+32')
    const phoneNumber = ref('')
    const phoneCountryRegex = ref('|(4)[0-9]{8}')
    const phonePlaceHolder = ref('499123456')

    const recaptchaKey = ref(globalI18n.value.recaptcha)
    const recaptcha = ref('')
    const gdpr = ref(false)
    const { currentLang } = useGetters(['currentLang'])
    const regExMail = ref({
      // eslint-disable-next-line no-useless-escape, max-len
      regex: /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/,
    })

    const phoneError = computed(() => {
      if (!phoneNumber.value) {
        return false
      }
      const regex = new RegExp(`^(${phoneCountryRegex.value})$`)
      const isValid = regex.test(phoneNumber.value)

      return isValid
    })

    watch(phoneError, newValue => {
      const bool = !newValue
      ctx.root.$emit('error:phone', Boolean(bool))
    })

    const setRecaptcha = (res: string) => {
      recaptcha.value = res
    }

    const resetRecaptcha = () => {
      recaptcha.value = ''
    }

    watch(gdpr, () => {
      politiqueValid.value = gdpr.value && recaptcha.value !== ''
    })

    watch(recaptcha, () => {
      politiqueValid.value = recaptcha.value !== '' && gdpr.value
    })

    watch(installateurActif, () => {
      onInput()
    })

    ctx.root.$on(
      `phone:flag:updated:${formName}`,
      (emitted: { phoneCode: string; placeHolder: string; regex: string }) => {
        phoneCode.value = emitted.phoneCode
        phonePlaceHolder.value = emitted.placeHolder
        phoneCountryRegex.value = emitted.regex
      }
    )

    const optionsPower = ref([
      '3.7 kW',
      '7.4 kW',
      '11 kW',
      '22 kW',
      currentLang.value === 'fr' ? autreFr.value : autreDe.value,
    ])

    const dateInitMEF = ref(new Date())
    const dateInitMHF = ref(new Date())

    onBeforeMount(async () => {
      if (!auth.isLogged.value) {
        ctx.root.$router.push({
          name: 'MySignin',
        })
      }

      await axios
        .get(`${getApiUrl()}/myresa/bornes/${uuid}`)
        .then(res => {
          terminal.value = res.data

          if (terminal.value && terminal.value.ean) {
            isFindTerminal.value = true

            if (
              terminal.value.installateur &&
              terminal.value.installateur.nom.length > 0
            ) {
              installateurActif.value = true
              if (
                terminal.value.installateur.telephone &&
                terminal.value.installateur.telephone.length > 0
              ) {
                if (terminal.value.installateur.telephone.startsWith('+33')) {
                  phoneCode.value = '+33'
                  phoneNumber.value = terminal.value.installateur.telephone.substring(
                    3,
                    terminal.value.installateur.telephone.length
                  )
                }
                if (terminal.value.installateur.telephone.startsWith('+32')) {
                  phoneCode.value = '+32'
                  phoneNumber.value = terminal.value.installateur.telephone.substring(
                    3,
                    terminal.value.installateur.telephone.length
                  )
                }
                if (terminal.value.installateur.telephone.startsWith('+352')) {
                  phoneCode.value = '+352'
                  phoneNumber.value = terminal.value.installateur.telephone.substring(
                    4,
                    terminal.value.installateur.telephone.length
                  )
                }
                if (phoneNumber.value.length <= 0) {
                  phoneNumber.value = terminal.value.installateur.telephone
                }
              }
            }

            if (terminal.value.borne) {
              if (
                terminal.value.borne.utilisation &&
                terminal.value.borne.utilisation.valeur === 'PRIVATE'
              ) {
                utilisationPublic.value = false
              }

              if (terminal.value.borne.bidirectionnelle) {
                isBidirectionnelle.value = terminal.value.borne.bidirectionnelle
              }
              puissanceSelect.value =
                currentLang.value === 'fr' ? autreFr.value : autreDe.value
              if (terminal.value.borne.puissance) {
                if (
                  Number(terminal.value.borne.puissance) === Number(3.7) ||
                  Number(terminal.value.borne.puissance) === Number(7.4) ||
                  Number(terminal.value.borne.puissance) === Number(11) ||
                  Number(terminal.value.borne.puissance) === Number(22)
                ) {
                  puissanceSelect.value = `${terminal.value.borne.puissance} kW`
                }
              }

              dateInitMEF.value = new Date()
              if (terminal.value.borne.dateActivation) {
                const year = Number(
                  terminal.value.borne.dateActivation.substring(0, 4)
                )
                const month = Number(
                  terminal.value.borne.dateActivation.substring(5, 7)
                )
                const day = Number(
                  terminal.value.borne.dateActivation.substring(8, 10)
                )

                dateInitMEF.value = new Date(year, month - 1, day)

                dateMEF.value = moment(dateInitMEF.value)
              }
              // DateDesactivation
              dateInitMHF.value = new Date()
              if (terminal.value.borne.dateDesactivation) {
                const year = Number(
                  terminal.value.borne.dateDesactivation.substring(0, 4)
                )
                const month = Number(
                  terminal.value.borne.dateDesactivation.substring(5, 7)
                )
                const day = Number(
                  terminal.value.borne.dateDesactivation.substring(8, 10)
                )

                dateInitMHF.value = new Date(year, month - 1, day)

                dateMHF.value = moment(dateInitMHF.value)
              }

              if (
                terminal.value.borne.photo &&
                terminal.value.borne.photo !== ''
              ) {
                changeFile.value = false
              }

              etatBorne.value = terminal.value.borne.active
            }
          }

          onInput()
          isLoad.value = true
        })
        .catch(e => {
          logger.trace(e)
          logger.trace('[terminal:onBeforeMount] FAILED ')
          isLoad.value = true
        })
    })

    const onInput = () => {
      formValid.value = true
      if (installateurActif.value) {
        if (terminal.value.installateur.nom === '') {
          formValid.value = false
        }
      }
      if (terminal.value.borne.marque === '') {
        formValid.value = false
      }
      if (terminal.value.borne.modele === '') {
        formValid.value = false
      }
      if (
        puissanceSelect.value === autreFr.value ||
        puissanceSelect.value === autreDe.value
      ) {
        if (Number(terminal.value.borne.puissance) <= 0) {
          formValid.value = false
        }
      }
      if (terminal.value.borne.serial === '') {
        formValid.value = false
      }
    }

    const onSubmit = () => {
      if (Number(puissanceSelect.value.split(' ')[0]) > 0) {
        terminal.value.borne.puissance = Number(
          puissanceSelect.value.split(' ')[0]
        )
      }

      terminal.value.installateur.telephone = `${phoneCode.value}${phoneNumber.value}`

      if (!installateurActif.value) {
        terminal.value.installateur.nom = ''
        terminal.value.installateur.email = ''
        terminal.value.installateur.telephone = ''
      }

      const entAcronyme = ref('')
      const entFormeJuridique = ref('')
      const entNom = ref('')
      const entNumero = ref('')

      if (terminal.value.entreprise) {
        entAcronyme.value = terminal.value.entreprise.acronyme
        entFormeJuridique.value = terminal.value.entreprise.formeJuridique
        entNom.value = terminal.value.entreprise.nom
        entNumero.value = terminal.value.entreprise.numero
      }

      if (etatBorne.value) {
        terminal.value.borne.dateActivation = dateMEF.value.format('YYYY-MM-DD')
      } else {
        terminal.value.borne.dateDesactivation = dateMHF.value.format(
          'YYYY-MM-DD'
        )
      }

      isLoad.value = false
      axios
        .put(`${getApiUrl()}/myresa/bornesUpdate/${uuid}`, {
          Ean: terminal.value.ean,
          Borne: {
            Adresse: {
              Numero: terminal.value.borne.adresse.numero,
              Rue: terminal.value.borne.adresse.rue,
              CodePostal: terminal.value.borne.adresse.codePostal,
              Commune: terminal.value.borne.adresse.commune,
              Pays: terminal.value.borne.adresse.pays,
            },
            Marque: terminal.value.borne.marque,
            Serial: terminal.value.borne.serial,
            Ean: terminal.value.borne.ean,
            Utilisation: {
              Libelle: utilisationPublic.value
                ? i18n.value.bornes.specificData.dataUsePublic
                : i18n.value.bornes.specificData.dataUsePrivate,
              valeur: utilisationPublic.value ? 'PUBLIC' : 'PRIVATE',
            },
            Puissance: Number(terminal.value.borne.puissance),
            Photo: terminal.value.borne.photo,
            Modele: terminal.value.borne.modele,
            Bidirectionnelle: isBidirectionnelle.value,
            DateDesactivation: terminal.value.borne.dateDesactivation,
            DateActivation: terminal.value.borne.dateActivation,
            Active: etatBorne.value,
          },
          Entreprise: {
            FormeJuridique: entFormeJuridique.value,
            Numero: entNumero.value,
            Nom: entNom.value,
            Acronyme: entAcronyme.value,
          },
          DateCreation: terminal.value.dateCreation,
          Uuid: terminal.value.uuid,
          Demandeur: {
            Prenom: terminal.value.demandeur.prenom,
            Email: terminal.value.demandeur.email,
            Telephone: terminal.value.demandeur.telephone,
            Nom: terminal.value.demandeur.nom,
          },
          Installateur: {
            Telephone: terminal.value.installateur.telephone,
            Nom: terminal.value.installateur.nom,
            Email: terminal.value.installateur.email,
          },
        })
        .then(resp => {
          ctx.root.$router.push({
            name: 'chargingTerminalsStatus',
            params: { status: 'success' },
          })
        })
        .catch(e => {
          logger.trace('[terminal:Update] FAILED')
          ctx.root.$router.push({
            name: 'chargingTerminalsStatus',
            params: { status: 'error' },
          })
        })
    }

    return {
      regExMail,
      formName,
      i18n,
      globali18n: globalI18n.value.i18n,
      user,
      isLogged: auth.isLogged,
      terminal,
      uuid,
      onInput,
      onSubmit,
      formValid,
      politiqueValid,
      optionsPower,
      installateurActif,
      utilisationPublic,
      isBidirectionnelle,
      etatBorne,
      isLoad,
      isFindTerminal,
      dateMEF,
      dateMHF,
      dateInitMEF,
      dateInitMHF,
      puissanceSelect,
      recaptchaKey,
      recaptcha,
      gdpr,
      currentLang,
      setRecaptcha,
      resetRecaptcha,
      inputUpload,
      onDelete,
      onInputFile,
      autreFr,
      autreDe,
      phoneCode,
      phoneNumber,
      phoneCountryRegex,
      phonePlaceHolder,
      changeFile,
      deleteFile,
    }
  },
})
